import React, { Suspense, lazy } from 'react'
import { Routes,
         Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { StoneProvider } from './myModules/reactStone.js'

import * as f from './utils/functions.js'
import enJson from './data/en.json'



const Home = lazy(() => import('./components/Home.js'))



class App extends React.Component {
  constructor(props) {
    super(props)

    window.onerror = function(err) {
      console.log('###err = ', err)
      return true
    }

    let catalogs = {
      'en': {
        "plural-forms": "nplurals=2; plural=(n > 1);",
        "messages": {

        }
      },
      'ru':{
        "plural-forms": "nplurals=2; plural=(n > 1);",
        "messages": {
          "Let's develop a website": [ "Разработаем Web-сайт" ],
          "Are you looking for web development? Fill out the form for a free</br> consultation on web development and our specialist</br> will contact and advise you!": ["Вы ищете веб-разработку? Заполните форму бесплатной</br> консультации по веб-разработке и наш специалист</br> свяжется и проконсультирует вас!"],
          "Name": ["Имя"],
          "Your email": ["Ваш email"],
          "To get a consultation": ["Получить консультацию"],
          "Or contact us at": ["Или свяжитесь с нами по"],
          "phone": ["телефон"],
          "Our work": ["Наши работы"],
          "freelance site": ["сайт фриланса"],
          "trading platform website": ["сайт торговые площадки"],
          "blog on wordpress": ["блог на вордпресс"],
          "aquarender company website": ["cайт компании акварендер"],
          "architect website": ["сайт архитектора"],
          "real estate website": ["сайт недвижимости"],
          "construction company website": ["сайт строительной компании"],
          "and many others": ["и много других"],
          "We can create any web project.</br> There is no project that we cannot</br> build. Everything is simple": ["Мы можем создать любой веб-проект.</br> Не существует проекта, который мы не можем</br> построить. Все просто"],
          "The email is received": ["Электронная почта получена"],
          "Required": ["Необходимо"],
          "Email already received": ["Электронная почта уже получена"],
          "Website development of any complexity | webdev": ["Разработка сайта любой сложности | webdev"],
          "Website development of any complexity. Inexpensive. Web development.": ["Разработка сайта любой сложности. Недорого. Веб разработка."],
          "Invalid email": ["Неверный email"]
        }
      },
      'ua':{
        "plural-forms": "nplurals=2; plural=(n > 1);",
        "messages": {
          "Let's develop a website": ["Розробимо Web-сайт"],
          "Are you looking for web development? Fill out the form for a free</br> consultation on web development and our specialist</br> will contact and advise you!": ["Ви шукаєте веб-розробку? Заповніть форму безкоштовної</br> консультації з веб-розробки і наш спеціаліст</br> зв'яжеться і проконсультує вас!"],
          "Name": ["Ім'я"],
          "Your email": ["Ваш email"],
          "To get a consultation": ["Отримати консультацію"],
          "Or contact us at": ["Або зв'яжіться з нами по"],
          "phone": ["телефон"],
          "Our work": ["Наші роботи"],
          "freelance site": ["сайт фрілансу"],
          "trading platform website": ["сайт торговельні майданчики"],
          "blog on wordpress": ["блог на вордпрес"],
          "aquarender company website": ["сайт компанії акварендер"],
          "architect website": ["сайт архітектора"],
          "real estate website": ["сайт нерухомості"],
          "construction company website": ["сайт будівельної компанії"],
          "and many others": ["і багато інших"],
          "We can create any web project.</br> There is no project that we cannot</br> build. Everything is simple": ["Ми можемо створити будь-який веб-проект.</br> Не існує проекту, який ми не можемо</br> побудувати. Все просто"],
          "The email is received": ["Електронну пошту отримано"],
          "Required": ["Необхідно"],
          "Email already received": ["Електронна пошта вже отримана"],
          "Website development of any complexity | webdev": ["Розробка сайту будь-якої складності webdev"],
          "Website development of any complexity. Inexpensive. Web development.": ["Розробка сайту будь-якої складності. Недорого. Веб розробка."],
          "Invalid email": ["Недійсний email"]
        }
      },
    }

    this.state = {
      catalogs
    }
  }

  componentWillUnmount() {
    // remove a recaptcha API
    f.deleteReCaptcha()
  }

  render() {
    // console.log('this.props = ', this.props)
    // console.log('this.state = ', this.state)

    let { props } = this

    return (
      <StoneProvider
        catalogs={this.state.catalogs}
        locale={this.props.globals.locale}
        className="two"
      >
        <Suspense fallback={
          <div>
            <div className="load" height="100%">
              Loading...
            </div>
          </div>
        }>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Suspense>
      </StoneProvider>
    )
  }
}

const mapStateToProps = state => {
  // const { expiresIn, token } = state.globals
  // return { expiresIn, token }
  const { globals } = state
  return { globals }
}

App = connect(mapStateToProps)(App)



export default App;
