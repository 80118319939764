import React from 'react'
import { Link } from 'react-router-dom'



// insert ReCaptcha
export const insertReCaptcha = (locale) => {
  if (!window.isLoadingReCaptcha) {
    window.isLoadingReCaptcha = true;

    // include recaptcha files
    window.onloadCallback = () => {
      window.grecaptchaLoaded = true
      // console.log('2window.grecaptcha = ', window.grecaptcha)
    }

    // 1. include a recaptcha API
    let head = document.getElementsByTagName('head')[0]
    let script = document.createElement('script')
    script.setAttribute('data-main', 'js/main');
    script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=${getReCaptchaLocale(locale)}`
    script.type = "text/javascript"
    script.async = true 
    script.defer = true
    script.id = 'my-script'

    // console.log('script = ', script)
    head.appendChild(script)
  }
}



// delete reCaptcha
export const deleteReCaptcha = () => {
  document.querySelectorAll('head script[src*=recaptcha]').forEach(e => e.parentNode.removeChild(e))
}



// get google captcha locale
export const getReCaptchaLocale = (locale) => {
  if (locale === 'ua') {
    return 'uk'
  } else if (locale === 'in') {
    return 'hi'
  } else if (locale === 'cn') {
    return 'zh-CN'
  } else {
    return locale
  }
}