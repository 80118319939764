import Cookie from 'js-cookie'

// // 1. locale initialization
// let locale = window.localStorage.getItem('locale')
// if (!locale) {
//   window.localStorage.setItem('locale', 'en')
//   locale = 'en'
// }

let locales = {
  "en": true,
  "ru": true,
  "ua": true,
}

// #############################
// 1. locale initialization
// #############################
let locale = ''
let params = new URLSearchParams(window.location.search)
let lang = params.get("lang")
if (locales[lang]) {
  locale = lang
  window.localStorage.setItem('locale', locale)
  Cookie.set('locale', locale, { expires: 1000 })
} else {
  
  locale = window.localStorage.getItem('locale')

  if (locales[locale]) {
    Cookie.set('locale', locale, { expires: 1000 })
  } else {
    locale = 'ru'
    window.localStorage.setItem('locale', locale)
    Cookie.set('locale', locale, { expires: 1000 })
  }
}

// console.log('locale = ', locale)

// 2. user initialization
let login = ''


const initialState = {
  locale,
}

// console.log('initialState = ', initialState)

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOCALE': {
      const { locale } = action.payload
      return {
        ...state,
        locale
      }
    }
    case 'SET_ID_LOGIN_TOKEN': {
      const obj = action.payload
      // let { expiresIn } = action.payload
      // expiresIn = +new Date + expiresIn * 1000
      return {
        ...state,
        ...obj,
        // expiresIn
      }
    }
    case 'REMOVE_ID_LOGIN_TOKEN': {
      return {
        ...state,
        id: '',
        login: '',
        token: '',
        // expiresIn: '',
        avatar: ''
      }
    }
    case 'RECEIVE_TOKEN': {
      let { token, expiresIn } = action.payload
      expiresIn = +new Date() + expiresIn * 1000
      return {
        ...state,
        token,
        expiresIn
      }
    }
    case 'UPDATE_LOGIN': {
      let { login } = action.payload
      return {
        ...state,
        login
      }
    }
    case 'UPDATE_AVATAR': {
      let { avatar } = action.payload
      return {
        ...state,
        avatar
      }
    }
    case 'SET_MESSAGE_REDIRECT': {
      let { messageRedirectId } = action.payload
      return {
        ...state,
        messageRedirect: { messageRedirectId }
      }
    }
    case 'REMOVE_MESSAGE_REDIRECT': {
      return {
        ...state,
        messageRedirect: {}
      }
    }
    case 'SET_PROJECT_FORM': {
      let { title, description, specializations, budget, deadline, language } = action.payload
      return {
        ...state,
        projectForm: { title, description, specializations, budget, deadline, language }
      }
    }
    case 'REMOVE_PROJECT_FORM': {
      return {
        ...state,
        projectForm: {}
      }
    }
    case 'SET_OFFER_FORM': {
      let { toId, description, budget, deadline, projectId } = action.payload
      return {
        ...state,
        offerForm: { toId, description, budget, deadline, projectId }
      }
    }
    case 'REMOVE_OFFER_FORM': {
      return {
        ...state,
        offerForm: {}
      }
    }
    case 'SET_ORDER_FORM': {
      let { title, description, budget, deadline, freelancerId } = action.payload
      return {
        ...state,
        orderForm: { title, description, budget, deadline, freelancerId }
      }
    }
    case 'REMOVE_ORDER_FORM': {
      return {
        ...state,
        orderForm: {}
      }
    }
    case 'CHANGE_VIEW_EDIT_PROFILE': {
      let { viewEditProfile } = action.payload
      return {
        ...state,
        viewEditProfile
      }
    }

    default: return state
  }
}